import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import chatGPT from '../../assets/chatgpt.png';

export default function ChatMessage({ initial, messages }) {

  const msgEnd = useRef(null);

  useEffect(() => {
    if (msgEnd.current) {
      msgEnd.current.scrollIntoView();
    }
  }, [messages]);
    
  return (
    <ChatContainer data-testid='chat-message-component'>
      {messages.map((message, index) => (
        message.isUser
          ? <UserMessage key={index}>
              <UserMessageContent>{message.text}</UserMessageContent>
              <UserAvatar data-testid='user-avatar'>{initial}</UserAvatar>
            </UserMessage>
          : <BotMessage key={index}>
              <GPTAvator src={chatGPT}  alt='chatgpt'/>
              <BotMessageContent>
                <Markdown>{message.text}</Markdown>
                {/* {
                  message.sources && (
                    <div>
                      <h4>Sources:</h4>
                      <ul>
                        {message.sources.map((source, index) => (
                          <li key={index}>
                            <a href={source} target="_blank" rel="noopener noreferrer">{source}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                } */}
              </BotMessageContent>
            </BotMessage>
      ))}

      <div ref={msgEnd} />
    </ChatContainer>
  )
}

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80%;
  width: 100%;
  overflow-y: auto;
  padding: 10px 80px;
  margin-top: 2rem;
`

const UserMessage = styled.div`
  align-self: flex-end;
  display: flex;
  max-width: 50%;
`
const UserMessageContent = styled.div`
  margin: auto;
  background-color: #f2f2f2;
  color: black;
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  line-height: 1.5rem;
`

const UserAvatar = styled.span`
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
`

const BotMessage = styled.div`
  align-slef: flex-start;
  margin: 5px 0;
  max-width: 50%;
  display: flex;
  
  a {
    color: white;
    padding: 0;
    margin: 0;
  }

  h4 {
    margin: 1rem 0 0.3rem 0;
    padding: 0;
  }

  ul {
    margin: 0;
  }
`

const GPTAvator = styled.img`
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  margin-right: 1rem;
  margin-top: 1rem;
`

const BotMessageContent = styled.div`
  margin: 0px;
  background-color: black;
  color: white;
  padding: 0px 15px 15px 15px;
  border-radius: 10px;
  word-wrap: break-word;
  line-height: 1.5rem;
`