import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ChatMessage from './ChatMessage.js';
import { getInitial } from '../../utils/getInitial.js';
import useUserData from '../../hooks/useUserData.js';
import { getFormattedDate } from '../../utils/getFormattedDate.js';
import { transformMessageList } from '../../utils/transformMessageList.js';

/*
  Input: 
    name => used to get the user's initial, ex 'XY'
    thread => passed from SearchEngine. Two situations:
                1. if it's null, create a new thread, 
                2. else retrieve the given thread and get its message history
    isNewChat => whether to create a chat
    setIsNewChat => reset isNewChat state
    onSelect => pass the current thread to the parent component to highlight it
*/
export default function ChatPanel({ name, threadId, isNewChat, setIsNewChat, onSelect }) {

  const [input, setInput] = useState("");
  const initialMessages = [
    {
      text: "Hi, I'm here to help with information about events, dates, and chat-based questions. How can I help you today? Sample questions:\n - Meeting summary on [Date]",
      sources: null,
      isUser: false,
    }
  ]
  const [messages, setMessages] = useState(initialMessages);
  const [newThreadId, setNewThreadId] = useState(threadId || null);
  const [isCreatingThread, setIsCreatingThread] = useState(false);

  const { addToUserList, getUserList, getItemByThreadId, editUserList } = useUserData();

  useEffect(() => {
    setNewThreadId(threadId);
  }, [threadId]);

  useEffect(() => {
    if (newThreadId === null) {
      setMessages(initialMessages);
    }
  }, [newThreadId]);

  // create a new thread if the newThreadId is null
  // else retrieve the given thread
  useEffect(() => {
    if (isNewChat || (isCreatingThread && !newThreadId)) {
      createNewThread();
      setIsNewChat(false);
    } else if (newThreadId) {
      retrieveThread();
    }
    // console.log(JSON.stringify(getUserList()))
  }, [isNewChat, newThreadId, isCreatingThread]);

  const createNewThread = async () => {
    try {
      const result = await fetch(`${process.env.REACT_APP_BASE_URL}/create_thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({  }),
      });
  
      const data = await result.json();
      setNewThreadId(data.id);
      
      // formated date and add chat into the user list
      const formattedDate = getFormattedDate(data.created_at);
      const chat = { title: 'New Chat', created_at: formattedDate, thread_id: data.id}
      addToUserList(chat)

      await sendMessage(data.id);
      setIsCreatingThread(false);  // Reset the state
    } catch (error) {
      alert(error);
    }
  };

  const retrieveThread = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/retrieve_thread?thread_id=${newThreadId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // get the message list
      const messageList = await getThreadMessageList(newThreadId);
      const transformedMessageList = transformMessageList(messageList.data);
      setMessages(transformedMessageList);

      if (onSelect) {
        onSelect(newThreadId);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const createMessage = async (thread_id, text) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/create_message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id: thread_id, content: text })
      });
  
      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }
  
      return await response.json();
    } catch (error) {
      console.error('Error creating message:', error);
      return null;
    }
  }

  const getThreadMessageList = async (thread_id) =>  {
    // console.log('inside getThreadMessageList, get: ' + threadId)
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/get_message_list?thread_id=${thread_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const messageList = await response.json();
      return messageList;
    } catch (error) {
      console.error('Error fetching thread messages:', error);
      return null;
    }
  }

  const runThread = async (thread_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/run_openai_assistant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id: thread_id }),
      });

      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      return await response.json();
    } catch (error) {
      console.error('Error running OpenAI assistant:', error);
      return null;
    }
  };

  const handleMessageSubmit = async () => {
    if (!newThreadId) {
      setIsCreatingThread(true);
    } else {
      await sendMessage(newThreadId);
    }
  };

  const sendMessage = async (threadId) => {
    // console.log(threadId, input)
    if (validateInput(input)) {
      try {
        const text = input;
        setInput("");
        setMessages([
          ...messages,
          { text: text, sources: null, isUser: true },
          { text: "Searching...", sources: null, isUser: false}
        ])

        // create a message and send it to the thread
        // console.log('create message: ' + threadId)
        createMessage(threadId, text);

        // run the assitant
        // console.log('run: ' + threadId)
        const run = await runThread(threadId);

        // get the message list and add the last message into the message list
        const messageList = await getThreadMessageList(threadId);
        const lastMessage = messageList.data
          .filter((message) => message.run_id === run.id && message.role === 'assistant')
          .pop();

        if (lastMessage) {
          setMessages([
            ...messages,
            { text: text, sources: [], isUser: true },
            { text: lastMessage.content[0]["text"]["value"], sources: [], isUser: false },
          ]);
        }

        const item = getItemByThreadId(threadId);
        if (item.title === 'New Chat') {
          editUserList(item.id, input);
        }

        // console.log(JSON.stringify(getUserList()))
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
  };

  const validateInput = (inputValue) => {
    if (inputValue === null || inputValue.trim() === '') {
      return false;
    }
    return true;
  };
  
  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleEnter = async (e) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      e.preventDefault();
      const inputValue = e.target.value;
      if (validateInput(inputValue)) {
        await handleMessageSubmit();
      }
    }
  };

  const initial = getInitial(name);

  return (
    <RightSection data-testid='chat-panel-component'>
      <ChatMessage messages={messages} initial={initial}/>
      <InputContainer>
        <input 
          type='text'
          placeholder='Ask a question...'
          value={input}
          onKeyDown={handleEnter}
          onChange={(e) => handleInput(e)}
        />
        <button onClick={handleMessageSubmit}>Send</button>
      </InputContainer>
    </RightSection>
  )
}

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  padding: 10px;

  input {
    width: 70%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid black;
    outline: none;
  }

  button {
    width: 20%;
    padding: 10px;
    margin-left: 1rem;
    border-radius: 20px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    cursor: pointer;
    outline: #4caf50;
    font-size: 1rem;
    transition: background-color 0.2s, transform 0.2s;

    &:hover {
      opacity: 0.8;
      transform: translateY(-1px);
    }
  }
`