import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import wpFavicon from '../../assets/wpfavicon.png';
import useUserData from '../../hooks/useUserData.js';
import ChatCard from './ChatCard.js';

export default function ChatSideBar({ name, email, setIsNewChat, onSelect, selectedThreadId }) {
  const { getUserList, subscribeToUserList } = useUserData();
  const { instance } = useMsal();

  const [chatHistoryList, setChatHistoryList] = useState(getUserList());
  
  // Monitor chatlist in real time and update
  useEffect(() => {
    const handleUserListChange = (newList) => {
      setChatHistoryList(newList);
    };

    const unsubscribe = subscribeToUserList(handleUserListChange);

    return () => {
      unsubscribe();
    };
  }, [subscribeToUserList]);

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      instance.logoutRedirect();
    }
  };

  return (
    <LeftSection data-testid='chat-side-bar-component'>
      <Header>
        <Logo src={wpFavicon} alt='western post logo'/>
        <h1>AI Search Engine</h1>
        <h3>User: {name}</h3>
        <p>Email: {email}</p>
      </Header>
      <NewButton onClick={() => setIsNewChat(true)}>New Chat</NewButton>
      <HR />
      <Body>
        <FooterText>Chat history can only be saved for 30 days</FooterText>
        {
          chatHistoryList.map((chat, index) => (
            <ChatCard key={index} chat={chat} onSelect={onSelect} selectedThreadId={selectedThreadId} />
          ))
        }
      </Body>
      <LogoutButtonContainer>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </LogoutButtonContainer>
  </LeftSection>
  )
}

const LeftSection = styled.div`
  min-width: 350px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid black;
`;

const Header = styled.div`
  height: 22vh;
  width: 100%;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1, h3, p {
    margin: 7px;
  }
`;

const Logo = styled.img`
  height: 65px;
  margin-bottom: -10px;
`

const Body = styled.div`
  height: 65%;
  width: 100%;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
`;

const FooterText = styled.p`
  font-size: 0.75rem;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  text-align: center;
  color: grey;
`;

const HR = styled.hr`
  padding: 4px 0 0 0;
  border: none;
  border-bottom: 1px solid black;
  width: 347px;
  height: 1px;
`;


const NewButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  background-color: black;
  color: white;
  margin-top: 13px;
  width: 90%;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
  }
`;

const LogoutButtonContainer = styled.div`
  height: 13%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoutButton = styled.button`
  padding: 8px 20px;
  font-size: 1.1rem;
  border-radius: 20px;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
  }
`;