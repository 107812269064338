import { useMsal } from '@azure/msal-react'
import React from 'react'
import styled from 'styled-components'

export default function Login() {

  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect({
      scopes: ['user.read']
    });
  }

  return (
    <LoginContainer>
      <LoginCard>
        <h1>Western Post Search Engine</h1>
        <p>Please <LoginButton onClick={handleLogin}>Login</LoginButton> with your Microsoft 365 account</p>
      </LoginCard>
    </LoginContainer>
  )
}

const LoginContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  height: 15%;
  // background: #f0f0f0;  // 给卡片一个背景色，方便观察

  h1 {
    font-size: 3rem;
    margin: 0;
  }

  p {
    font-size: 1.3rem;
    margin: 0;
  }
`

const LoginButton = styled.button`
  max-width: 5rem;
  font-size: 1.3rem;
`