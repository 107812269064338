import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FiMoreVertical } from 'react-icons/fi';
import useUserData from '../../hooks/useUserData.js';

export default function ChatCard({ chat, onSelect, selectedThreadId }) {
  const { deleteFromUserList, editUserList, getUserList, getItemByThreadId } = useUserData();

  const [showDropdown, setShowDropdown] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const dropdownRef = useRef(null);
  const editingRef = useRef(null);

  useEffect(() => {
    setInputValue(getItemByThreadId(chat.thread_id).title);
  }, [chat]);

  const handleIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }

    if (isEditing && editingRef.current && !editingRef.current.contains(event.target)) {
      setInputValue(chat.title);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, showDropdown]);

  const handleDelete = () => {
    deleteFromUserList(chat.id);
    handleDeleteThread(chat.thread_id);

    onSelect(null);
    setShowDropdown(false);

    // console.log(JSON.stringify(getUserList()));
  };

  const handleDeleteThread = async (threadId) => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/delete_thread?thread_id=${threadId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      onSelect(null);
      // console.log('Thread deleted successfully');
    } catch (e) {
      alert(e.message);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setShowDropdown(false);
  };

  const handleUpdate = () => {
    editUserList(chat.id, inputValue);
    setIsEditing(false);
  };

  const handleEnter = async (e) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      handleUpdate();
    }
  };

  const handleSelect = () => {
    onSelect(chat.thread_id);
  }

  return (
    <Container data-testid='chatcard-component' onClick={handleSelect} selected={selectedThreadId === chat.thread_id}>
      <TitleWrapper ref={editingRef}>
        {isEditing ? (
          <>
            <EditInput 
              data-testid='chatcard-editInput'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleEnter}
            />
          </>
        ) : (
          `${chat.created_at} ${chat.title}`
        )}
      </TitleWrapper>
      <IconWrapper data-testid='chatcard-iconWrapper' onClick={handleIconClick} ref={dropdownRef}>
        <FiMoreVertical data-testid='chatcard-icon' />
        {showDropdown && (
          <Dropdown data-testid='chatcard-dropdown'>
            <DropdownItem onClick={handleEdit}>Edit</DropdownItem>
            <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
          </Dropdown>
        )}
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  // border: 1px solid black;
  border-radius: 20px;
  background-color: ${({ selected }) => (selected ? '#DDDDDD' : '#F4F4F4')};
  margin: 0px 7px 5px 7px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#DDDDDD' : '#DDDDDD')};
    transform: translateY(-1px);
    z-index: 10;
  }
`;

const TitleWrapper = styled.div`
  margin: 0;
  padding: 8px 20px;
  font-size: 0.95rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  flex: 1;
`;

const IconWrapper = styled.span`
  padding: 8px;
  font-size: 0.93rem; 
  min-width: 35px;
  max-width: 35px;
  cursor: pointer;
  position: relative;
`;

const Dropdown = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const EditInput = styled.input`
  padding: 5px;
  margin-left: 8px;
  margin-right: 11px;
  font-size: 0.95rem;
  width: 174px;
`;

const UpdateButton = styled.button`
  padding: 3px;
  font-size: 0.9rem;
  cursor: pointer;
  background: black;
  color: white;
`;
