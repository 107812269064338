import React, { useEffect, useState } from 'react';
import './App.css';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Login from './components/Login/Login.js';
import SearchEngine from './components/SearchEngine/SearchEngine.js';
import useUserData from './hooks/useUserData.js';

export default function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const [user, setUser] = useState({ name: "", email: "" });

  const { setUserData } = useUserData();

  useEffect(() => {
    // If not authenticated, use Redirect to automatically log in
    if (isAuthenticated) {
      const account = instance.getActiveAccount();
      if (account) {
        // set azure user
        setUser({ name: account.name, email: account.username });
        // save individual user info to local storage for chat history
        setUserData({ account: account.username });
      }
    } else if (!isAuthenticated) {
      instance.ssoSilent({
        scopes: ["user.read"],
        loginHint: ""
      }).then((response) => {
        instance.setActiveAccount(response.account);
        setUser({ name: response.account.name, email: response.account.username });
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.loginRedirect({
            scopes: ['users.read'],
          });
        }
      })
    } 
  }, [instance, isAuthenticated]);

  return (
    <div className="App">
      {
        isAuthenticated 
        ? <SearchEngine name={user.name} email={user.email}/>
        : <Login />
      }
    </div>
  );
}
