import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ChatSideBar from './ChatSideBar.js';
import ChatPanel from './ChatPanel.js'
import useUserData from '../../hooks/useUserData.js';

export default function SearchEngine({ name, email }) {

  const { subscribeToUserList, getItemByThreadId } = useUserData();

  const [threadId, setThreadId] = useState(null);
  const [isNewChat, setIsNewChat] = useState(false);

  useEffect(() => {
    const handleUserListChange = (changedThreadId) => {
      if (!getItemByThreadId(changedThreadId) && threadId === changedThreadId) {
        setThreadId(null);
      }
    };

    const unsubscribe = subscribeToUserList(handleUserListChange);

    return () => {
      unsubscribe();
    };
  }, [subscribeToUserList, getItemByThreadId]);

  const handleThreadSelect = (selected_thread_id) => {
    if (threadId !== selected_thread_id) {
      setThreadId(selected_thread_id);
    }
  };

  const handleIsNewChat = () => {
    setIsNewChat(true);
  }
  
  // clearUserList();

  return (
    <Container data-testid='search-engine-component'>
      <ChatSideBar name={name} email={email} setIsNewChat={handleIsNewChat} onSelect={handleThreadSelect} selectedThreadId={threadId} />
      <ChatPanel name={name} threadId={threadId} isNewChat={isNewChat} setIsNewChat={setIsNewChat} onSelect={handleThreadSelect} />
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const NewChat = styled.button`
  position: absolute;
  left: 17px;
  top: 192px;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 20px;
  background-color: black;
  color: white;
  margin-bottom: 20px;
  min-width: 315px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
  }
`