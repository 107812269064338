export const transformMessageList = (thread_messages) => {
  const cur_messages = thread_messages.map(message => {
    return {
      role: message.role,
      content: message.content[0].text.value
    };
  });

  const result = [
    {
      text: "Hi, I'm here to help with information about events, dates, and chat-based questions. How can I help you today? Sample questions:\n - Meeting summary on [Date]",
      sources: null,
      isUser: false,
    }
  ];
  cur_messages.reverse().forEach(message => {
    result.push({
      text: message.content,
      source: [],
      isUser: message.role === 'user'
    })
  });

  return result;
}