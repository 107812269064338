export const getFormattedDate = (created_at) => {
    const date = new Date(created_at * 1000);

    const month = date.getUTCMonth() + 1; // 月份从 0 开始
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // formatted as 'mm-dd-yyyy'
    const formattedDate = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    return formattedDate;
}