import { useEffect } from 'react';
import eventEmitter from './eventEmitter.js';

const USER_LIST_UPDATED = 'USER_LIST_UPDATED';

const useUserData = () => {
  useEffect(() => {
    // 在组件挂载时检查并清理过期数据
    checkAndClearExpiredData();
  }, []);

  const setUserData = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    const timestamp = new Date().getTime();
    localStorage.setItem('timestamp', timestamp);
  };

  const getUserData = () => {
    return JSON.parse(localStorage.getItem('user'));
  };

  const addToUserList = (item) => {
    const userList = JSON.parse(localStorage.getItem('userList')) || [];
    
    // 找到现有列表中的最大 id
    const maxId = userList.length > 0 ? Math.max(...userList.map(chat => chat.id)) : 0;
    
    // 为新项设置 id
    const newItem = { id: maxId + 1, ...item };
    
    // 将新项添加到列表头，使chat list最新的在最上面
    userList.unshift(newItem);
    localStorage.setItem('userList', JSON.stringify(userList));
    
    // 发射事件
    eventEmitter.emit(USER_LIST_UPDATED, userList);
  };

  const getUserList = () => {
    return JSON.parse(localStorage.getItem('userList')) || [];
  };

  const clearUserList = () => {
    localStorage.removeItem('userList');
    eventEmitter.emit(USER_LIST_UPDATED, []);
  };

  const deleteFromUserList = (id) => {
    const userList = JSON.parse(localStorage.getItem('userList')) || [];
    const updatedList = userList.filter(item => item.id !== id);
    localStorage.setItem('userList', JSON.stringify(updatedList));
    
    // 发射事件
    eventEmitter.emit(USER_LIST_UPDATED, updatedList);
  };

  const editUserList = (id, newTitle) => {
    const userList = JSON.parse(localStorage.getItem('userList')) || [];
    const updatedList = userList.map(item => 
      item.id === id ? { ...item, title: newTitle } : item
    );
    localStorage.setItem('userList', JSON.stringify(updatedList));
    
    // 发射事件
    eventEmitter.emit(USER_LIST_UPDATED, updatedList);
  };

  const getItemByThreadId = (thread_id) => {
    const userList = JSON.parse(localStorage.getItem('userList')) || [];

    const item = userList.find(item => item.thread_id === thread_id);

    return item || null;
  };

  const subscribeToUserList = (callback) => {
    eventEmitter.on(USER_LIST_UPDATED, callback);

    return () => {
      eventEmitter.off(USER_LIST_UPDATED, callback);
    };
  };

  return {
    setUserData,
    getUserData,
    addToUserList,
    getUserList,
    clearUserList,
    deleteFromUserList,
    editUserList,
    getItemByThreadId,  // 将 getItemByThreadId 函数添加到返回对象中
    subscribeToUserList,
  };
};

// 检查和清理过期数据的函数
const checkAndClearExpiredData = () => {
  const timestamp = localStorage.getItem('timestamp');
  if (timestamp) {
    const currentTime = new Date().getTime();
    const thirtyDays = 30 * 24 * 60 * 60 * 1000; // 30天的毫秒数

    if (currentTime - timestamp > thirtyDays) {
      // 超过30天，清除数据
      localStorage.removeItem('user');
      localStorage.removeItem('userList');
      localStorage.removeItem('timestamp');
    }
  }
};

export default useUserData;
